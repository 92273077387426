import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { axiosRequest } from '../common-api/axios';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STEPS_LIST_ROWS_DEF, STEPS_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { stat } from 'fs';
const initialState = {
  caseData: [],
  reminderData: [],
  eventsData: [],
  notificationStatusData: [],
  loading: false,
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STEPS_LIST_ROWS_DEF,
    sortFieldProp: STEPS_DEFAULT_SORT_COLUMN,
    searchAlienNumber: '',
    searchCurrentlyinUS: "",
    searchNationalities: [],
    searchLanguage: [],
    searchstartDate: '',
    searchendDate: '',
    searchCaseType: '',
    searchCaseStatus: '',
    searchDesignations: []
  },
  fillingAdded: false,
  activeTab: "",
  childActiveTab: "",
  refreshDerivativeData: false,
  refreshPetitionerData: false,
  lastSavedSectionID: null,
  refreshCaseData: true,
  receiptViewForNewWindow: false,
  receiptIDForNewWindow: '',
};


/**
 * Getting Case List
 *
 * @type {*}
 */
export const getCaseListAction = createAsyncThunk(
  "caseTypesModule/getCaseListAction",
  async (value: any) => {
    const payload = {
      "page": String(value.currentPage),
      "size": String(value.rows),
      "sort": value.sortField,
      "sort_order": value.sortOrder,
      "search": value.searchKey ? value.searchKey : '',
      "searchCaseSelfAssignee": value.searchCaseAssginee ?? '',
      "searchAlienNumber": value.searchAlienNumber ? value.searchAlienNumber : '',
      "searchCaseType": value.searchCaseType ? value.searchCaseType : [],
      "searchCaseStatus": value.searchCaseStatus ? value.searchCaseStatus : '',
      "searchCurrentlyinUS": value.searchCurrentlyinUS ?? '',
      "searchNationalities": value.searchNationalities?.length > 0 ? value.searchNationalities : [],
      "searchLanguage": value.searchLanguage?.length > 0 ? value.searchLanguage : [],
      "searchstartDate": value.searchstartDate ? value.searchstartDate : '',
      "searchendDate": value.searchendDate ? value.searchendDate : '',
      "searchByDesignatedUsers": value.searchDesignations ? value.searchDesignations : []
    }
    const apiUrl = EndPoint.CASE_LIST_API
    try {
      const response = await axiosRequest.post(apiUrl, payload);
      return response;
    } catch (error: any) {
      return error;

    }
  }
);

/**
 * Getting Desgination List
 *
 * @type {*}
 */
export const getCaseDesignationilteredListAction = createAsyncThunk(
  "caseTypesModule/getCaseDesignationilteredListAction",
  async () => {
    try {
      const response = await axiosRequest.get(EndPoint.DESIGNATION_LIST_FILTERED);
      return response;
    } catch (error: any) {
      return error;

    }
  }
);

//ADD CLIENT CASES
export const addCaseReassignDesignationAction = createAsyncThunk(
  "teamsModule/addCaseReassignDesignationAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.REASSIGN_DESIGNATION, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Getting Case Data by ID
 *
 * @type {*}
 */
export const getCaseDataByIDAction = createAsyncThunk(
  "caseTypesModule/getCaseDataByIDAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(EndPoint.CASE_DATA_BY_ID + "/" + value.caseID);
      return response;
    } catch (error: any) {
      return error;

    }
  }
);

//EXPORT STAGES
export const exportCasesAction = createAsyncThunk(
  "teamsModule/exportCasesAction",
  async (value: any) => {
    const payload = {
      "sort": value.sortField,
      "sort_order": value.sortOrder,
      "search": value.searchKey ? value.searchKey : '',
      "searchCaseSelfAssignee": value.searchCaseAssginee ?? '',
      "searchAlienNumber": value.searchAlienNumber ? value.searchAlienNumber : '',
      "searchCaseType": value.searchCaseType ? value.searchCaseType : [],
      "searchCaseStatus": value.searchCaseStatus ? value.searchCaseStatus : '',
      "searchCurrentlyinUS": value.searchCurrentlyinUS ?? '',
      "searchNationalities": value.searchNationalities?.length > 0 ? value.searchNationalities : [],
      "searchLanguage": value.searchLanguage?.length > 0 ? value.searchLanguage : [],
      "searchstartDate": value.searchstartDate ? value.searchstartDate : '',
      "searchendDate": value.searchendDate ? value.searchendDate : '',
      "searchByDesignatedUsers": value.searchDesignations ? value.searchDesignations : []
    }
    const apiUrl = EndPoint.CASES_EXPORT
    try {
      const response = await axiosRequest.post(apiUrl, payload);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getNotesListAction = createAsyncThunk(
  "caseManagement/getNotesListAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.LIST_CASES_NOTES}?case_id=${value.caseID}&search=${value.searchKey}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const addNewNotesAction = createAsyncThunk(
  "caseManagement/addNewNotesAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_CASE_NOTES}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const editNotesInfoAction = createAsyncThunk(
  "caseManagement/editNotesInfoAction",

  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.ADD_CASE_NOTES}`, value);
      return response;
    } catch (error: any) {

      return error;

    }
  }
);

export const getNotesDetailAction = createAsyncThunk(
  "knowledgebaseModule/getNotesDetailAction",
  async (value: any) => {
    const apiUrl = `${EndPoint.ADD_CASE_NOTES}/${value.noteId}`
    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {
      return error;

    }
  }
);

export const deleteNotesAction = createAsyncThunk(
  "caseManagement/deleteNotesAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.ADD_CASE_NOTES}/${value.notes_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const exportNotesAction = createAsyncThunk(
  "caseManagement/exportNotesAction",
  async (value: any) => {
    try {
      const apiUrl = `${EndPoint.EXPORT_LIST_CASES_NOTES}?search=${value.searchKey}&notes_id=${value.selectedIds}`
      const response = await axiosRequest.get(apiUrl);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getFillingListAction = createAsyncThunk(
  "caseTypesModule/getFillingListAction",
  async (value: any) => {
    const apiUrl =
      `${EndPoint.CASE_FILLING_LIST}?case_id=${value.case_id}`;

    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {
      return error;

    }
  }
);

export const addFillingAction = createAsyncThunk(
  "knowledgebaseFormsModule/addFillingAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.CASE_ADD_FILLING}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const editFillingAction = createAsyncThunk(
  "knowledgebaseFormsModule/editFillingAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.CASE_EDIT_FILLING}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getFillingDetailAction = createAsyncThunk(
  "knowledgebaseModule/getFillingDetailAction",
  async (value: any) => {
    const apiUrl = `${EndPoint.CASE_ADD_FILLING}/${value.filling_id}`
    try {
      const response = await axiosRequest.get(apiUrl);

      return response;
    } catch (error: any) {
      return error;

    }
  }
);


export const deleteFillingAction = createAsyncThunk(
  "caseManagement/deleteFillingAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.CASE_FILLING_DELETE}/${value.filing_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getCaseStagesStepsAction = createAsyncThunk(
  "caseManagement/getCaseStagesStepsAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.CASE_STAGES_STEPS}?case_id=${value.case_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const renameCaseStepsAction = createAsyncThunk(
  "caseManagement/renameCaseStepsAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.RENAME_CASE_STEPS}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const deleteCaseStepsAction = createAsyncThunk(
  "caseManagement/deleteCaseStepsAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.DELETE_CASE_STEPS}/${value.case_stage_id}/${value.case_step_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const markAsCompletedCaseStepsAction = createAsyncThunk(
  "caseManagement/markAsCompletedCaseStepsAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.STEP_MARK_COMPLETED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const markStageAsNotApplicableAction = createAsyncThunk(
  "caseManagement/markStageAsNotApplicableAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.MARK_STAGE_NOTAPPLICABLE}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const reArrangeStepsIDAction = createAsyncThunk(
  "caseTypesModule/reArrangeStepsIDAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.REARRANGE_STAGES, value);
      return response;
    } catch (error: any) {
      return error;

    }
  }
);

export const markStepAsNotApplicableCaseStepsAction = createAsyncThunk(
  "caseManagement/markStepAsNotApplicableCaseStepsAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.MARK_STEP_NOTAPPLICABLE}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW STEPS TO CASES
export const addCaseStageSteps = createAsyncThunk(
  "teamsModule/addCaseStageSteps",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.ADD_NEW_STAGE_STEP, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW STEPS TO CASES BY CASE TYPES
export const addCaseStageStepsCaseTypes = createAsyncThunk(
  "teamsModule/addCaseStageStepsCaseTypes",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.ADD_NEW_STEPS_CASE_TYPES, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW STEPS TO CASES BY CASE TYPES
export const getAllApplicantList = createAsyncThunk(
  "teamsModule/getAllApplicantList",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_APPLICANT_LIST}?case_id=${value.caseTypesID}`)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW STEPS TO CASES BY CASE TYPES
export const getAllCaseQuestionnaires = createAsyncThunk(
  "teamsModule/getAllCaseQuestionnaires",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.CASE_QUESTIONNAIRE_LIST}?case_id=${value.caseTypesID}&search=${value.searchKey}&client_id=${value.client_id}&derivates_id=${value.derivates_id}`)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const saveQuestionnaireForCase = createAsyncThunk(
  "teamsModule/saveQuestionnaireForCase",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.CASE_QUESTIONNAIRE}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const questionnaireMarkAsSent = createAsyncThunk(
  "teamsModule/questionnaireMarkAsSent",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.SEND_QUESTIONNAIRE}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const questionnaireMarkAsCompleted = createAsyncThunk(
  "teamsModule/questionnaireMarkAsCompleted",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.COMPLETED_QUESTIONNAIRE}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const questionnaireMarkAsReviewed = createAsyncThunk(
  "teamsModule/questionnaireMarkAsReviewed",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.REVIEW_QUESTIONNAIRE}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const deleteCaseQuestionnaireAction = createAsyncThunk(
  "caseManagement/deleteCaseQuestionnaireAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.DELETE_QUESTIONNAIRE}/${value.case_id}/${value.questionnaire_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const caseNotificationAction = createAsyncThunk(
  "caseManagement/caseNotificationAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.CASE_NOTIFICATIONS}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * GETTING ALL DERIVATIVES LIST
 * @date 08/01/2024 - 18:32:37
 *
 * @type {*}
 */
export const getAllDerivativeAction = createAsyncThunk(
  "caseManagement/getAllDerivativeAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_ALL_DERIVATIVE}?case_id=${value.case_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * ADD DERIVATIVES
 * @date 08/01/2024 - 18:32:37
 *
 * @type {*}
 */
export const addDerivativeAction = createAsyncThunk(
  "caseManagement/addDerivativeAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_DERIVATIVE}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * DELETE DERIVATIVES
 * @date 08/01/2024 - 18:32:37
 *
 * @type {*}
 */
export const deleteDerivativeAction = createAsyncThunk(
  "caseManagement/deleteDerivativeAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.DELETE_DERIVATIVE}/${value.derivativeId}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW STEPS TO CASES BY CASE TYPES
export const getAllQuestionnairesViewAction = createAsyncThunk(
  "teamsModule/getAllQuestionnairesViewAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.QUESTIONNAIRE_VIEW}?case_id=${value.caseID}&questionnaire_id=${value.questionnaireID}`)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const submitCaseQuestionnnaireAction = createAsyncThunk(
  "teamsModule/submitCaseQuestionnnaireAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.SUBMIT_QUESTIONNAIRE}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const notApplicableCaseQuestionnnaireAction = createAsyncThunk(
  "teamsModule/notApplicableCaseQuestionnnaireAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.NOTAPPLICABLE_QUESTIONNAIRE}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const addDocChkListForCase = createAsyncThunk(
  "teamsModule/addDocChkListForCase",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_DOC_CHK_LIST}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


export const getAllDocChkListViewAction = createAsyncThunk(
  "teamsModule/getAllDocChkListViewAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.VIEW_DOC_CHK_LIST}?case_id=${value.caseID}&search=${value.search}&client_id=${value.client_id}&derivates_id=${value.derivates_id}`)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW STEPS TO CASES BY CASE TYPES
export const getParticularDocChkListViewAction = createAsyncThunk(
  "teamsModule/getParticularDocChkListViewAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.VIEW_PARTICULAR_DOC_CHK_LIST}?case_id=${value.caseID}&chklist_id=${value.chklist_id}`)
      return response;
    } catch (error: any) {
      return error;
    }
  }
)

/**
 * GETTING ALL DERIVATIVES LIST
 *
 * @type {*}
 */
export const getAllPetitionerAction = createAsyncThunk(
  "caseManagement/getAllPetitionerAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_ALL_PETITIONERS}?case_id=${value.case_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * ADD DERIVATIVES
 *
 * @type {*}
 */
export const addPetitionerAction = createAsyncThunk(
  "caseManagement/addPetitionerAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_PETITIONERS}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * DELETE DERIVATIVES
 *
 * @type {*}
 */
export const deletePetitionerAction = createAsyncThunk(
  "caseManagement/deletePetitionerAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.DELETE_PETITIONERS}/${value.petitioner_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const changeDocChkListSendStatusForCase = createAsyncThunk(
  "teamsModule/changeDocChkListSendStatusForCase",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.DOC_CHK_LIST_SEND}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const changeDocChkListAddStatusForCase = createAsyncThunk(
  "teamsModule/changeDocChkListAddStatusForCase",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.DOC_CHK_LIST_ADD}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * GETTING ALL DERIVATIVES LIST
 *
 * @type {*}
 */
export const getAllDocumentsByCaseAction = createAsyncThunk(
  "caseManagement/getAllDocumentsByCaseAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.GET_CASE_DOCUMENT}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const documentMarkAsRequested = createAsyncThunk(
  "teamsModule/documentMarkAsRequested",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.DOCUMENT_REQUESTED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const documentMarkAsReceived = createAsyncThunk(
  "teamsModule/documentMarkAsReceived",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.DOCUMENT_RECIEVED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const documentMarkAsApprove = createAsyncThunk(
  "teamsModule/documentMarkAsReceived",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.DOCUMENT_APPROVE}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const documentMarkAsSigned = createAsyncThunk(
  "teamsModule/documentMarkAsSigned",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.DOCUMENT_SIGNED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const documentMarkAsReRequest = createAsyncThunk(
  "teamsModule/documentMarkAsReRequest",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.DOCUMENT_RE_REQUESTED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const documentMarkAsReRequestAll = createAsyncThunk(
  "teamsModule/documentMarkAsReRequestAll",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.DOCUMENT_RE_REQUESTED_ALL}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * DELETE DERIVATIVES
 *
 * @type {*}
 */
export const deleteCaseDocumentAction = createAsyncThunk(
  "caseManagement/deleteCaseDocumentAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.DELETE_CASE_DOCUMENT}/${value.doc_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getDocDetialsByIDAction = createAsyncThunk(
  "caseManagement/getDocDetialsByIDAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.VIEW_CASE_DOCUMENT}/${value.doc_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getDocContentByIDAction = createAsyncThunk(
  "caseManagement/getDocContentByIDAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.DOCUMENT_VIEW_DATA}/${value.file_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getDocSampleContentByIDAction = createAsyncThunk(
  "caseManagement/getDocSampleContentByIDAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.DOCUMENT_SAMPLE_VIEW_DATA}/${value.file_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const uploadCaseDocumentsByCaseAction = createAsyncThunk(
  "caseManagement/uploadCaseDocumentsByCaseAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.UPLOAD_DOCUMENT}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const edituploadCaseDocumentsByCaseAction = createAsyncThunk(
  "caseManagement/edituploadCaseDocumentsByCaseAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.EDIT_UPLOAD_DOCUMENT}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const moveFolderCaseDocumentsByCaseAction = createAsyncThunk(
  "caseManagement/moveFolderCaseDocumentsByCaseAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.MOVE_DOCUMENT_FOLDER}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getAllFormsByCaseAction = createAsyncThunk(
  "caseManagement/getAllFormsByCaseAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.GET_CASE_FORMS}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const deleteCaseFormAction = createAsyncThunk(
  "caseManagement/deleteCaseFormAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.DELETE_CASE_FORM}/${value.form_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const formMarkAsSent = createAsyncThunk(
  "teamsModule/formMarkAsSent",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.FORM_SENT}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const formMarkAsApprove = createAsyncThunk(
  "teamsModule/formMarkAsApprove",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.FORM_APPROVE}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const formMarkAsSigned = createAsyncThunk(
  "teamsModule/formMarkAsSigned",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.FORM_SIGNED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


export const getFormContentByIDAction = createAsyncThunk(
  "caseManagement/getFormContentByIDAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.FORM_VIEW_DATA}/${value.file_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getAllSigningAuthCasesAction = createAsyncThunk(
  "composeEmailModule/getAllSigningAuthCasesAction",
  async (value: any) => {
    const apiUrl = `${EndPoint.GET_ALL_SIGNING_AUTH}`
    try {
      const response = await axiosRequest.get(apiUrl + '?case_id=' + value.case_id);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD CLIENT CASES
export const setSigningAuthFormsAction = createAsyncThunk(
  "teamsModule/setSigningAuthFormsAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.SET_SIGNING_AUTH, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getAllFormCompare = createAsyncThunk(
  "caseManagement/getAllFormCompare",
  async (id: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_ALL_FORM_COMPARE}?case_id=${id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const getAllDocumentCompare = createAsyncThunk(
  "caseManagement/getAllDocumentCompare",
  async (id: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_ALL_DOCUMENT_COMPARE}?case_id=${id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Form Receipt listing by Id
export const getFormsReceiptDataByIDAction = createAsyncThunk(
  "caseManagement/getFormContentByIDAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_FORMS_RECEIPT_DATA}?case_id=${value.case_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW RECEIPT
export const addNewReceipt = createAsyncThunk(
  "caseManagement/addNewReceipt",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_NEW_RECEIPT}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Receipt Listing API
export const getFormsReceiptListing = createAsyncThunk(
  "caseManagement/getFormsReceiptListing",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_RECEIPT_LIST}?case_id=${value.case_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Receipt Delete  from list
export const deleteReceiptAction = createAsyncThunk(
  "caseManagement/deleteReceiptAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.DELETE_RECEIPT}/${value.receipt_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Delect Child receipt
export const deleteChildReceiptAction = createAsyncThunk(
  "caseManagement/deleteChildReceiptAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.DELETE_CHILD_RECEIPT}/${value.receipt_id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


// Receipt Mark as Primary
export const markAsPrimaryReceiptAction = createAsyncThunk(
  "caseManagement/markAsPrimaryReceiptAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.PRIMARY_RECEIPT, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Receipt Status Change 
export const changeStatusReceiptAction = createAsyncThunk(
  "caseManagement/changeStatusReceiptAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.CHANGE_STATUS_RECEIPT, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Receipt OCR details  
export const fetchOCRDetailsReceiptAction = createAsyncThunk(
  "caseManagement/fetchOCRDetailsReceiptAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_OCR_DETAILS_RECEIPT}/${value}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


//Ocr Compare Submit RECEIPT
export const OcrCompareSubmitReceipt = createAsyncThunk(
  "caseManagement/OcrCompareSubmitReceipt",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.OCR_COMPARE_SUBMIT_RECEIPT, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Process Details for Sponsored Visa Submit or Update
export const processDetailsForSponsoredVisaSubmit = createAsyncThunk(
  "caseManagement/processDetailsForSponsoredVisaSubmit",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.EDIT_PROCESS_DETAILS_FOR_SPONSORED_VISA, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


export const addNewFormsCaseForCase = createAsyncThunk(
  "teamsModule/addNewFormsCaseForCase",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_NEW_FORM_CASE}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//Visa Priority Details update
export const visaPriorityDetailsSubmit = createAsyncThunk(
  "caseManagement/visaPriorityDetailsSubmit",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.VISA_PRIORITY_UPDATE, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Receipt OCR details  
export const getAllDocsPreview = createAsyncThunk(
  "caseManagement/getAllDocsPreview",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_ALL_DOCS_PREVIEW}?case_id=` + value.case_id);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const saveOrderOfDocuments = createAsyncThunk(
  "caseManagement/saveOrderOfDocuments",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.SAVE_ORDER_DOCUMENT, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const downloadBundleOfDocuments = createAsyncThunk(
  "caseManagement/downloadBundleOfDocuments",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(EndPoint.DOWNLOAD_BUNDLE_DOCUEMNT, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//GET ALL REMINDER LIST
export const getAllReminders = createAsyncThunk(
  "caseManagement/getAllReminders",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.GET_CASE_REMINDER_LIST}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW REMINDER
export const addNewReminder = createAsyncThunk(
  "caseManagement/addNewReminder",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_NEW_REMINDER}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//Get All Users List
export const getAllUsersList = createAsyncThunk(
  "caseManagement/getAllUsersList",
  async () => {
    try {
      const response = await axiosRequest.get(EndPoint.GET_ALL_USERS_LIST);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//Mark as Complete Reminder
export const markAsCompletedReminder = createAsyncThunk(
  "caseManagement/markAsCompletedReminder",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.REMINDER_MARK_COMPLETED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//Delete Reminder List
export const deleteReminderAction = createAsyncThunk(
  "caseManagement/deleteReminderAction",
  async (value: any) => {
      try {
          const response = await axiosRequest.delete(`${EndPoint.REMINDER_DELETE}/${value.reminder_id}`);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

//VIEW REMINDERS
export const viewReminderAction = createAsyncThunk(
  "caseManagement/viewReminderAction",
  async (value: any) => {
      const apiUrl = `${EndPoint.REMINDER_VIEW}?reminder_id=${value.reminder_id}`
      try {
          const response = await axiosRequest.get(apiUrl);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

//EDIT REMINDERS 
export const editReminderAction = createAsyncThunk(
  "caseManagement/editReminderAction",
  async (value: any) => {
      try {
          const response = await axiosRequest.put(`${EndPoint.REMINDER_EDIT}`, value);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

//GET ALL EVENTS LIST
export const getAllEventsAction = createAsyncThunk(
  "caseManagement/getAllEventsAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.GET_CASE_EVENTS_LIST}`, value)
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//ADD NEW EVENT
export const addNewEventAction = createAsyncThunk(
  "caseManagement/addNewEventAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.ADD_NEW_EVENTS}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//MARK AS COMPLETE EVENT
export const markAsCompletedEventAction = createAsyncThunk(
  "caseManagement/markAsCompletedEventAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.EVENTS_MARK_COMPLETED}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

//VIEW EVENTS
export const viewEventsAction = createAsyncThunk(
  "caseManagement/viewEventsAction",
  async (value: any) => {
      const apiUrl = `${EndPoint.EVENTS_VIEW}?event_id=${value.event_id}`
      try {
          const response = await axiosRequest.get(apiUrl);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

//EDIT EVENTS 
export const editEventsAction = createAsyncThunk(
  "caseManagement/editEventsAction",
  async (value: any) => {
      try {
          const response = await axiosRequest.put(`${EndPoint.EVENTS_EDIT}`, value);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

//DELETE EVENT
export const deleteEventAction = createAsyncThunk(
  "caseManagement/deleteEventAction",
  async (value: any) => {
      try {
          const response = await axiosRequest.delete(`${EndPoint.EVENTS_DELETE}/${value.event_id}`);
          return response;
      } catch (error: any) {
          return error;
      }
  }
);

//Case fill form
export const caseFillForm = createAsyncThunk(
  "caseManagement/caseFillForm",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.CASE_FORM_FILL_FORM}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const includeFileInBundle = createAsyncThunk(
  "caseManagement/includeFileInBundle",
  async (value: any) => {
    try {
      const response = await axiosRequest.put(`${EndPoint.INCLUDING_FILE_IN_BUNDLE}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

/**
 * Link to Open Case Folder
 *
 * @type {*}
 */
export const getLinkToOpenCaseFolder = createAsyncThunk(
  "caseManagement/getLinkToOpenCaseFolder",
  async (value: any) => {
    try {
      const response = await axiosRequest.get(`${EndPoint.GET_LINK_TO_OPEN_CASE_FOLDER}/${value}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Delete Multiple Case Management
export const deleteCaseManagementAction = createAsyncThunk(
  "caseManagement/deleteCaseManagementAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.post(`${EndPoint.CASE_MANAGEMENT_MULTIPLE_DELETE}`, value);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// Delete Single Case Management
export const deleteSingleCaseManagementAction = createAsyncThunk(
  "caseManagement/deleteSingleCaseManagementAction",
  async (value: any) => {
    try {
      const response = await axiosRequest.delete(`${EndPoint.CASE_MANAGEMENT_SINGLE_DELETE}/${value.id}`);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);


const caseMgtSlice = createSlice({
  name: 'Case Management',
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;
      state.listObj.searchAlienNumber = actions.payload.searchAlienNumber;
      state.listObj.searchCurrentlyinUS = actions.payload.searchCurrentlyinUS;
      state.listObj.searchNationalities = actions.payload.searchNationalities;
      state.listObj.searchLanguage = actions.payload.searchLanguage;
      state.listObj.searchstartDate = actions.payload.searchstartDate;
      state.listObj.searchendDate = actions.payload.searchendDate;
      state.listObj.searchCaseType = actions.payload.searchCaseType;
      state.listObj.searchCaseStatus = actions.payload.searchCaseStatus;
      state.listObj.searchDesignations = actions.payload.searchDesignations;

    },
    changeFillingAddedStatus(state, actions) {
      state.fillingAdded = actions.payload.fillingAdded;
    },
    refreshCaseDataAction(state, actions) {
      state.refreshCaseData = actions.payload.refreshCaseData;
    },
    receiptViewHandlingForNewTab(state, actions) {
      state.receiptViewForNewWindow = actions.payload
    },
    receiptIDHandlingForNewTab(state, actions) {
      state.receiptIDForNewWindow = actions.payload
    },

    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
    activeTabStatus(state, actions) {
      state.activeTab = actions.payload.activeTab;
    },
    childAtiveTabStatus(state, actions) {
      state.childActiveTab = actions.payload.childActiveTab;
    },

    /**
     * REFRESH DERIVATIVE LIST
     * @date 08/01/2024 - 18:33:45
     *
     * @param {*} state
     * @param {*} actions
     */
    RefreshDerivativeStatus(state, actions) {
      state.refreshDerivativeData = actions.payload.refreshDerivativeData;
    },
    saveLastSection(state, action) {
      console.log("action.payload.lastSavedSectionID", action.payload.lastSavedSectionID)
      state.lastSavedSectionID = action.payload.lastSavedSectionID
    },
    RefreshPetitionerDataStatus(state, actions) {
      state.refreshPetitionerData = actions.payload.refreshPetitionerData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCaseListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCaseListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getCaseListAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addCaseReassignDesignationAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCaseReassignDesignationAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addCaseReassignDesignationAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(exportCasesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportCasesAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(exportCasesAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCaseDataByIDAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCaseDataByIDAction.fulfilled, (state, action) => {
        state.caseData = []
        let data = action.payload.data ? action.payload.data : []
        state.caseData = data;
        state.loading = false;
      })
      .addCase(getCaseDataByIDAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getNotesListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotesListAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getNotesListAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewNotesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewNotesAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(addNewNotesAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getNotesDetailAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotesDetailAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getNotesDetailAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editNotesInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editNotesInfoAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(editNotesInfoAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteNotesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotesAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteNotesAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(exportNotesAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportNotesAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(exportNotesAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addFillingAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFillingAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(addFillingAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editFillingAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editFillingAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(editFillingAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteFillingAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFillingAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteFillingAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getFillingDetailAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFillingDetailAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getFillingDetailAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getFillingListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFillingListAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getFillingListAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCaseStagesStepsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCaseStagesStepsAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getCaseStagesStepsAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addDocChkListForCase.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDocChkListForCase.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(addDocChkListForCase.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllDocChkListViewAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDocChkListViewAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getAllDocChkListViewAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getParticularDocChkListViewAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getParticularDocChkListViewAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getParticularDocChkListViewAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(changeDocChkListSendStatusForCase.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeDocChkListSendStatusForCase.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(changeDocChkListSendStatusForCase.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(changeDocChkListAddStatusForCase.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeDocChkListAddStatusForCase.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(changeDocChkListAddStatusForCase.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllFormCompare.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFormCompare.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getAllFormCompare.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllDocumentCompare.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDocumentCompare.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getAllDocumentCompare.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getFormsReceiptDataByIDAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormsReceiptDataByIDAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getFormsReceiptDataByIDAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewReceipt.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewReceipt.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(addNewReceipt.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getFormsReceiptListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormsReceiptListing.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getFormsReceiptListing.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteReceiptAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReceiptAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteReceiptAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteChildReceiptAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChildReceiptAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteChildReceiptAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(markAsPrimaryReceiptAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(markAsPrimaryReceiptAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(markAsPrimaryReceiptAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(changeStatusReceiptAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeStatusReceiptAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(changeStatusReceiptAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchOCRDetailsReceiptAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOCRDetailsReceiptAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(fetchOCRDetailsReceiptAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(OcrCompareSubmitReceipt.pending, (state) => {
        state.loading = true;
      })
      .addCase(OcrCompareSubmitReceipt.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(OcrCompareSubmitReceipt.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(processDetailsForSponsoredVisaSubmit.pending, (state) => {
        state.loading = true;
      })
      .addCase(processDetailsForSponsoredVisaSubmit.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(processDetailsForSponsoredVisaSubmit.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(visaPriorityDetailsSubmit.pending, (state) => {
        state.loading = true;
      })
      .addCase(visaPriorityDetailsSubmit.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(visaPriorityDetailsSubmit.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewReminder.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewReminder.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(addNewReminder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllUsersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersList.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getAllUsersList.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllReminders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReminders.fulfilled, (state, action) => {
        state.reminderData = []
        let data = action.payload.data ? action.payload.data : []
        state.reminderData = data;
        state.loading = false;
      })
      .addCase(getAllReminders.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(markAsCompletedReminder.pending, (state) => {
        state.loading = true;
      })
      .addCase(markAsCompletedReminder.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(markAsCompletedReminder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteReminderAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReminderAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteReminderAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(viewReminderAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewReminderAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(viewReminderAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editReminderAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editReminderAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(editReminderAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllEventsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEventsAction.fulfilled, (state, action) => {
        state.eventsData = []
        let data = action.payload.data ? action.payload.data : []
        state.eventsData = data;
        state.loading = false;
      })
      .addCase(getAllEventsAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewEventAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewEventAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(addNewEventAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(markAsCompletedEventAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(markAsCompletedEventAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(markAsCompletedEventAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteEventAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEventAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteEventAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(viewEventsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewEventsAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(viewEventsAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editEventsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editEventsAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(editEventsAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(includeFileInBundle.pending, (state) => {
        state.loading = true;
      })
      .addCase(includeFileInBundle.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(includeFileInBundle.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(caseNotificationAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(caseNotificationAction.fulfilled, (state, action) => {
        state.notificationStatusData = []
        let data = action.payload.data ? action.payload.data : []
        state.notificationStatusData = data;
        state.loading = false;
      })
      .addCase(caseNotificationAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getLinkToOpenCaseFolder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLinkToOpenCaseFolder.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getLinkToOpenCaseFolder.rejected, (state, action) => {
        state.loading = false;
      })
      // Delete Multiple
      .addCase(deleteCaseManagementAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCaseManagementAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteCaseManagementAction.rejected, (state, action) => {
        state.loading = false;
      })
       // Delete Single
       .addCase(deleteSingleCaseManagementAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSingleCaseManagementAction.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteSingleCaseManagementAction.rejected, (state, action) => {
        state.loading = false;
      })
  }
});

export const caseMgtActions = caseMgtSlice.actions;

export default caseMgtSlice.reducer;