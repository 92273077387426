import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EndPoint from "../../api/Endpoint";
import { axiosRequest } from "../common-api/axios";

const initialState = {
    searchKeyGlobal: "",
    searchKey: "",
    allGlobalSearchData: [],
    loading:false,
};

// All search
export const getAllGlobalSearchData = createAsyncThunk(
    "globalSearchModule/getAllGlobalSearchData",
    async (value:any) => {
        try {
            const response = await axiosRequest.post(EndPoint.GLOBAL_SEARCH_ALL, value);
            return response;
        } catch (error:any) {        
            return error;
        }
    }
);

// Client Search
export const getClientGlobalSearchData = createAsyncThunk(
    "globalSearchModule/getClientGlobalSearchData",
    async (value:any) => {
        try {
            const response = await axiosRequest.post(EndPoint.GLOBAL_SEARCH_CLIENT, value);
            return response;
        } catch (error:any) {        
            return error;
        }
    }
);

// Cases Search
export const getCasesGlobalSearchData = createAsyncThunk(
    "globalSearchModule/getCasesGlobalSearchData",
    async (value:any) => {
        try {
            const response = await axiosRequest.post(EndPoint.GLOBAL_SEARCH_CASES, value);
            return response;
        } catch (error:any) {        
            return error;
        }
    }
);

// Relative Search
export const getRelativeGlobalSearchData = createAsyncThunk(
    "globalSearchModule/getRelativeGlobalSearchData",
    async (value:any) => {
        try {
            const response = await axiosRequest.post(EndPoint.GLOBAL_SEARCH_RELATIVES, value);
            return response;
        } catch (error:any) {        
            return error;
        }
    }
);

const globalSearchSlice: any = createSlice({
    name: 'Global Search',
    initialState: initialState,
    reducers: {
        searchKeyGlobalFun(state,actions){
            state.searchKeyGlobal = actions.payload.search
        },
        searchKeyFun(state,actions) {
            state.searchKey = actions.payload
        },
        startLoader(state,actions) {
            state.loading = true;
        },
        stopLoader(state,actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder 
            .addCase(getAllGlobalSearchData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllGlobalSearchData.fulfilled, (state, action) => {
                state.loading = false;   
                let data = action.payload.combinedResult ? action.payload.combinedResult : []
                state.allGlobalSearchData = data
            })
            .addCase(getAllGlobalSearchData.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getClientGlobalSearchData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getClientGlobalSearchData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getClientGlobalSearchData.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCasesGlobalSearchData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCasesGlobalSearchData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getCasesGlobalSearchData.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getRelativeGlobalSearchData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRelativeGlobalSearchData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getRelativeGlobalSearchData.rejected, (state, action) => {
                state.loading = false;
            })
    }

});

export const globalSearchActions = globalSearchSlice.actions;

export default globalSearchSlice.reducer