import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STAGES_DEFAULT_SORT_COLUMN, STAGES_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    savedReportsData: [],
    loading:false,
    particularReportObj:{},
    listObj: {
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
        createdTypeFilterProp: "",
        searchAlienNumber: '',
        searchCurrentlyinUS: "",
        searchNationalities: [],
        searchLanguage: [],
        searchstartDate: '',
        searchendDate: '',
        searchCaseType: '',
        searchCaseStatus: '',
        searchDesignations: []
    },
};

export const getSavedReportListAction = createAsyncThunk(
    "saveReportModule/getSavedReportListAction",
    async (value:any) => {
        const apiUrl = `${EndPoint.SAVED_REPORT_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&searchcreatedBy=${value.createdTypeFilter ? value?.createdTypeFilter?.toLowerCase() : ''}`
        try {
            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

// Delete
export const deleteSavedReportAction = createAsyncThunk(
    "saveReportModule/deleteSavedReportAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.DELETE_REPORT_API}/${value?.id}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);

// csv download
export const csvDownloadReportAction = createAsyncThunk(
    "saveReportModule/csvDownloadReportAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.DOWNLOAD_SINGLE_REPROT_API}/${value?.id}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);


// Edit Record
export const getUpdatedRecordForID = createAsyncThunk(
    "saveReportModule/getUpdatedRecordForID",
    async (value:any) => {
        const apiUrl = `${EndPoint.GET_EDIT_DATA_SAVED_REPORTS_API}/${value}`
        try {
            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);



const savedReportSlice:any = createSlice({
    name: 'Saved Reports',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
            state.listObj.createdTypeFilterProp=actions.payload.createdTypeFilter; 
        },
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSavedReportListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSavedReportListAction.fulfilled, (state, action) => {
                
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.savedReportsData = data;
            })
            .addCase(getSavedReportListAction.rejected, (state, action) => {
               state.loading = false;
            })       
            .addCase(deleteSavedReportAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteSavedReportAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteSavedReportAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(csvDownloadReportAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(csvDownloadReportAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(csvDownloadReportAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getUpdatedRecordForID.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUpdatedRecordForID.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getUpdatedRecordForID.rejected, (state, action) => {
               state.loading = false;
            })  
    }
});

export const savedReportActions = savedReportSlice.actions;

export default savedReportSlice.reducer;