import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import EndPoint from '../../api/Endpoint';
import { toast } from "react-toastify";
import { API_CUSTOM_MESSAGE } from '../../validations/validationErrors';
import { POP_UP_CLOSE_1000, POP_UP_CLOSE_2000, SUCCESS_STATUS, STAGES_LIST_ROWS_DEF, LIST_CRR_PAGE, DEF_SORT_ORDER, STAGES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    knowledgeBaseStageData: [],
    loading:false,
    particularStageObj:{},
    listObj: {
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STAGES_LIST_ROWS_DEF,
        sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
        caseTypeFilterProp: "",
        deletedStageProp: false,
    },
    caseTypeData:[]
};

export const getKnowledgeBaseStageListAction = createAsyncThunk(
    "knowledgebase&stageModule/getKnowledgeBaseStageListAction",
    async (value:any) => {
        const apiUrl = value.searchKey === "" ? 
        `${EndPoint.KB_STAGE_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&filterByCaseType=${value.caseTypeFilter ? value.caseTypeFilter : ''}&filtereByDeletedStage=${value.deletedStage}` : 
        `${EndPoint.KB_STAGE_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&filterByCaseType=${value.caseTypeFilter ? value.caseTypeFilter : ''}&filtereByDeletedStage=${value.deletedStage}`;
        try {
            const response = await axiosRequest.get(apiUrl);
          
            return response;
        } catch (error:any) {
                
            return error;
        }
    }
);

export const addNewStageAction = createAsyncThunk(
    "knowledgebase&stageModule/addNewStageAction",
    async (value:any) => {

        const addData:any = {
            stage_name : value.stageName,
            description: value.stageDescription
        }
        try {
            const response = await axiosRequest.post(`${EndPoint.KB_STAGE_ADD_API}`, addData);
            return response;
        } catch (error:any) {
        
            return error;
        }
    }
);

export const deleteStageAction = createAsyncThunk(
    "knowledgebase&stageModule/deleteStageAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.KB_STAGE_DELETE_API}/${value.stageId}`);
            return response;
        } catch (error:any) {
            return error;
        }
    }
);

export const getStageAction = createAsyncThunk(
    "knowledgebase&stageModule/getStageAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.KB_STAGE_GET_API}/${value}`);
          
            return response;
        } catch (error:any) {

            return error;
        }
    }
);

export const editStageAction = createAsyncThunk(
    "knowledgebase&stageModule/editStageAction",
    async (value:any) => {
        const editData = {
            id: value.stageId,
            stage_name: value.stage_name,
            description: value.description
        }
        try {
            
            const response = await axiosRequest.put(`${EndPoint.KB_STAGE_EDIT_API}`, editData);
            return response;
        } catch (error:any) {     
            return error;
        }
    }
);

/**
 * Getting all common Stages
 *
 * @type {*}
 */
export const getAllCommonStagesAction = createAsyncThunk(
    "knowledgebase&stageModule/getAllCommonStagesAction",
    async () => {
        try {
            const response = await axiosRequest.get(`${EndPoint.KB_STAGE_GET_API_COMMON}`);
          
            return response;
        } catch (error:any) {

            return error;
        }
    }
);

/**
 * Getting all case types
 *
 * @type {*}
*/

export const getAllCaseTypeAction = createAsyncThunk(
    "knowledgebase&stageModule/getAllCaseTypeAction",
    async () => {
        try {
            const response = await axiosRequest.get(`${EndPoint.CASES_COMMON_LIST_API}`);
            return response;
        } catch (error:any) {     
            return error;
        }
    }
);

//EXPORT STAGES
export const exportStagesAction = createAsyncThunk(
    "teamsModule/exportStagesAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ? `${EndPoint.STAGES_EXPORT}?sort=${value.sortField}&sort_order=${value.sortOrder}&filterByCaseType=${value.caseTypeFilter}&filtereByDeletedStage=${value.deletedStage}` :
        `${EndPoint.STAGES_EXPORT}?sort=${value.sortField}&search=${value.searchKey}&sort_order=${value.sortOrder}&filterByCaseType=${value.caseTypeFilter}&filtereByDeletedStage=${value.deletedStage}`
        try {

            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {

            return error;

        }
    }
);

// RESTORE STAGE
export const restoreStageAction = createAsyncThunk(
    "knowledgebase&stageModule/restoreStageAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.KB_STAGE_RESTORE_API}`, value);
            return response;
        } catch (error: any) {

            return error;

        }
    }
);

const knowledgeBaseSlice:any = createSlice({
    name: 'knowledgebase & stage',
    initialState: initialState,
    reducers: {
        changeListDataObj(state,actions) {
            state.listObj.searchKeyProp=actions.payload.searchKey;
            state.listObj.sortOrderProp=actions.payload.sortOrder;
            state.listObj.currentPageProp=actions.payload.currentPage;
            state.listObj.rowsProp=actions.payload.rows;
            state.listObj.sortFieldProp=actions.payload.sortField;
            state.listObj.caseTypeFilterProp=actions.payload.caseTypeFilter; 
            state.listObj.deletedStageProp = actions.payload.deletedStage;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getKnowledgeBaseStageListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKnowledgeBaseStageListAction.fulfilled, (state, action) => {
                
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.knowledgeBaseStageData = data;
            })

            .addCase(getKnowledgeBaseStageListAction.rejected, (state, action) => {
               state.loading = false;
            })

            .addCase(addNewStageAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewStageAction.fulfilled, (state, action) => {
                
                state.loading = false;
            })

            .addCase(addNewStageAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(deleteStageAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteStageAction.fulfilled, (state, action) => {
                state.loading = false;
        
            })
            .addCase(deleteStageAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getStageAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getStageAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : {}
                state.loading = false;
                state.particularStageObj = data;
            })
            .addCase(getStageAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(editStageAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(editStageAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(editStageAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(exportStagesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(exportStagesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(exportStagesAction.rejected, (state, action) => {
               state.loading = false;
            })
            .addCase(getAllCaseTypeAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllCaseTypeAction.fulfilled, (state, action) => {
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.caseTypeData = data;
            })
            .addCase(getAllCaseTypeAction.rejected, (state, action) => {
               state.loading = false;
            })   
            .addCase(restoreStageAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(restoreStageAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreStageAction.rejected, (state, action) => {
               state.loading = false;
            })
    }
});

export const knowledgeBaseStageActions = knowledgeBaseSlice.actions;

export default knowledgeBaseSlice.reducer;