import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import EndPoint from "../../api/Endpoint";
import { toast } from "react-toastify";
import { API_CUSTOM_MESSAGE } from "../../validations/validationErrors";
import {
  POP_UP_CLOSE_1000,
  POP_UP_CLOSE_2000,
  SUCCESS_STATUS,
  STAGES_LIST_ROWS_DEF,
  LIST_CRR_PAGE,
  DEF_SORT_ORDER,
  STAGES_DEFAULT_SORT_COLUMN,
} from "../../constants/global";
import { axiosRequest } from "../common-api/axios";

const initialState = {
  companyManagementData: [],
  partiCompanyObj: {},
  loading: false,
  listObj: {
    searchKeyProp: "",
    sortOrderProp: DEF_SORT_ORDER,
    currentPageProp: LIST_CRR_PAGE,
    rowsProp: STAGES_LIST_ROWS_DEF,
    sortFieldProp: STAGES_DEFAULT_SORT_COLUMN,
    FEINNumber: "",
    DBAName: "",
    billingStatus: [],
    startDate:  "",
    endDate: "",
  },
  activeTab: "companyDetails",
  // childActiveTab: "",
};

export const getCompanyListAction = createAsyncThunk(
	"companyManagementModule/getCompanyListAction",
	async (value:any) => {
			const apiUrl = value.searchKey === "" ? 
			`${EndPoint.COMPANY_MANAGEMENT_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&searchFEINNumber=${value.FEINNumber ? value.FEINNumber : '' }&searchDBAName=${value.DBAName ? value.DBAName : '' }&searchBillingStatus=${value.billingStatus ? value.billingStatus : ''}&searchstartDate=${value.startDate ? value.startDate : ''}&searchendDate=${value.endDate ? value.endDate : ''}` : 
			`${EndPoint.COMPANY_MANAGEMENT_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&searchFEINNumber=${value.FEINNumber ? value.FEINNumber : '' }&searchDBAName=${value.DBAName ? value.DBAName : '' }&searchBillingStatus=${value.billingStatus ? value.billingStatus : ''}&searchstartDate=${value.startDate ? value.startDate : ''}&searchendDate=${value.endDate ? value.endDate : ''}`;
			try {
				const response = await axiosRequest.get(apiUrl);
				return response;
			} catch (error:any) {
				return error;
			}
	}
);



export const addCompanyAction = createAsyncThunk(
	"companyManagementModule/addCompanyAction",
	async (value:any) => {
    delete value.file_number_manual
		try {
			const response = await axiosRequest.post(`${EndPoint.COMPANY_MANAGEMENT_ADD_API}`, value);
			return response;
		} catch (error:any) {
			return error;
		}
	}
);

export const getCompanyDetailsAction = createAsyncThunk(
	"companyManagementModule/getCompanyDetailsAction",
	async (value:any) => {
		try {
			const response = await axiosRequest.get(`${EndPoint.COMPANY_MANAGEMENT_DETAILS_API}/${value.id}`);
			return response;
		} catch (error:any) {
			return error;
		}
	}
);

export const updateCompanyAction = createAsyncThunk(
	"companyManagementModule/updateCompanyAction",
	async (value:any) => {
		try {
			const response = await axiosRequest.put(`${EndPoint.COMPANY_MANAGEMENT_UPDATE_API}`, value);
			return response;
		} catch (error:any) {
			return error;
		}
	}
);

//EXPORT STEPS
export const exportComapnyAction = createAsyncThunk(
  "companyManagementModule/exportComapnyAction",
  async (value: any) => {
    const apiUrl = value.searchKey === "" ? 
    `${EndPoint.COMPANY_MANAGEMENT_EXPORT_API}?sort=${value.sortField}&sort_order=${value.sortOrder}&searchFEINNumber=${value.FEINNumber ? value.FEINNumber : '' }&searchDBAName=${value.DBAName ? value.DBAName : '' }&searchBillingStatus=${value.billingStatus ? value.billingStatus : ''}&searchstartDate=${value.startDate ? value.startDate : ''}&searchendDate=${value.endDate ? value.endDate : ''}` : 
    `${EndPoint.COMPANY_MANAGEMENT_EXPORT_API}?search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&searchFEINNumber=${value.FEINNumber ? value.FEINNumber : '' }&searchDBAName=${value.DBAName ? value.DBAName : '' }&searchBillingStatus=${value.billingStatus ? value.billingStatus : ''}&searchstartDate=${value.startDate ? value.startDate : ''}&searchendDate=${value.endDate ? value.endDate : ''}`;
      try {
        const response = await axiosRequest.get(apiUrl);
        return response;
      } catch (error: any) {
        return error;
      }
  }
);


export const getCompanyViewAction = createAsyncThunk(
	"companyManagementModule/getCompanyViewAction",
	async (value:any) => {
		try {
			const response = await axiosRequest.get(`${EndPoint.COMPANY_MANAGEMENT_VIEW_API}/${value.id}`);
			return response;
		} catch (error:any) {
			return error;
		}
	}
);

const companyManagementSlice: any = createSlice({
  name: "Company Management",
  initialState: initialState,
  reducers: {
    changeListDataObj(state, actions) {
      state.listObj.searchKeyProp = actions.payload.searchKey;
      state.listObj.sortOrderProp = actions.payload.sortOrder;
      state.listObj.currentPageProp = actions.payload.currentPage;
      state.listObj.rowsProp = actions.payload.rows;
      state.listObj.sortFieldProp = actions.payload.sortField;
      state.listObj.FEINNumber = actions.payload.FEINNumber;
      state.listObj.DBAName = actions.payload.DBAName;
      state.listObj.billingStatus = actions.payload.billingStatus;
      state.listObj.startDate = actions.payload.startDate;
      state.listObj.endDate = actions.payload.endDate;
    },
    startLoader(state, actions) {
      state.loading = true;
    },
    stopLoader(state, actions) {
      state.loading = false;
    },
    activeTabStatus(state, actions) {
      state.activeTab = actions.payload.activeTab;
    },
    // childAtiveTabStatus(state, actions) {
    //   state.childActiveTab = actions.payload.childActiveTab;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyListAction.fulfilled, (state, action) => {
        let data = action.payload.data ? action.payload.data : [];
        state.loading = false;
        state.companyManagementData = data;
      })
      .addCase(getCompanyListAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(addCompanyAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCompanyAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addCompanyAction.rejected, (state, action) => {
        state.loading = false;
      })

			.addCase(getCompanyDetailsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        // state.partiCompanyObj = action.payload.data;
      })
      .addCase(getCompanyDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })


			.addCase(updateCompanyAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompanyAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateCompanyAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(exportComapnyAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportComapnyAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(exportComapnyAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getCompanyViewAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyViewAction.fulfilled, (state, action) => {
        state.loading = false;
        state.partiCompanyObj = action.payload.data;
      })
      .addCase(getCompanyViewAction.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const companyManagementActions = companyManagementSlice.actions;

export default companyManagementSlice.reducer;
