import React from "react";
import ReactDOM from "react-dom/client";

//Prime Theme
import "./assets/primereact-sass-theme/themes/material/material-light/compact/indigo/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import 'ckeditor5/ckeditor5-content.css';
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./helpers/auth-config";
import { injectStore } from "./redux/common-api/axios";
import { PersistGate } from 'redux-persist/integration/react'
import { injectStoreUploadImageEditor } from "./components/common/Editor/MyCustomImageUploadAdapterPlugin";

const msalInstance = new PublicClientApplication(msalConfig);
injectStore(store, msalInstance);
injectStoreUploadImageEditor(store);


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
