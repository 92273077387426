import { toast } from "react-toastify";
import EndPoint from "../../../api/Endpoint";
import appConfig from "../../../helpers/config";

let store: any;

export const injectStoreUploadImageEditor = (_store: any) => {
  store = _store;
};
const baseUrlApi = appConfig.apiEndPoint;

class MyCustomUploadAdapter {
  loader: any;
  maxFileSize: any;
  maxWidth: any;
  maxHeight: any;
  reader: any;
  imageSrcc: any;

  constructor(loader: any, maxFileSize: any, maxWidth: any, maxHeight: any, src: any) {
    this.loader = loader;
    this.maxFileSize = maxFileSize;
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;
    this.imageSrcc = src;
  }

  upload() {
    return this.loader.file.then(
      (file: any) =>
        new Promise((resolve, reject) => {

          // Check the file type
          const validTypes = ['image/jpeg','image/jpg','image/png'];
          if (!validTypes.includes(file.type)) {
            // reject(new Error('Invalid file type. Only JPG, JPEG and PNG are allowed.'));
            alert('Invalid file type. Only JPG, JPEG and PNG are allowed.');
            resolve({
              default: this.imageSrcc, // Assuming your server response contains the URL of the uploaded image
            });
            return;
          }

          // Check the file size
          if (file.size > this.maxFileSize) {
            // reject(
            //   `File is too large. Maximum size is ${
            //     this.maxFileSize / (1024 * 1024)
            //   } MB.`
            // );
            alert(`File is too large. Maximum size is ${
                  this.maxFileSize / (1024 * 1024)
                } MB.`)
            resolve({
              default: this.imageSrcc, // Assuming your server response contains the URL of the uploaded image
            });
            return;
          }
          // Check the aspect ratio
          const image = new Image();
          image.src = URL.createObjectURL(file);
          image.onload = () => {
            const aspectRatio = image.width / image.height;

            if (image.width > this.maxWidth || image.height > this.maxHeight) {
              // reject(
              //   new Error(
              //     `Image dimensions are too large. Maximum size is ${this.maxWidth}x${this.maxHeight} pixels.`
              //   )
              // );
              alert(`Image dimensions are too large. Maximum size is ${this.maxWidth}x${this.maxHeight} pixels.`)
              resolve({
                default: this.imageSrcc, // Assuming your server response contains the URL of the uploaded image
              });
              return;
            }
            const data = new FormData();
            data.append("files", file);
            data.append("file_ype", file.type);
            let tokenBearer: any = "";

            const token = store.getState().authSlice.authData;
            const authToken =
              token === undefined || token === null ? "" : token.jwt_token;
            if (token) {
              tokenBearer = `Bearer ${authToken}`;
            }

            fetch(`${baseUrlApi}/${EndPoint.UPLOAD_BLOB_TEXT_EDITOR_API}`, {
              // Replace with your server's upload URL
              method: "POST",
              body: data,
              headers: {
                Authorization: tokenBearer,
              },
            })
              .then((response) => {
                if (!response.ok) {
                  return response.json().then((error) => {
                    reject(error);
                  });
                }
                return response.json();
              })
              .then((data) => {
                resolve({
                  default: data.url, // Assuming your server response contains the URL of the uploaded image
                });
              })
              .catch((error) => {
                console.error("Upload error:", error);
                // toast.error("Image upload failed. Please try again.");
                reject(error);
              });
          };
        })
    );
  }

  abort() {
    // Handle the aborting of the upload process if needed
  }
}

export function MyCustomUploadAdapterPlugin(editor: any) {
  const maxFileSize = 1048576; // 1MB in bytes
  const maxWidth = 1200; // Maximum width in pixels
  const maxHeight = 1200; // Maximum height in pixels
  const viewDocument = editor.editing.view.document;
  let imageSrcc = ''; 
  // Listen for click events on the editor's view
  viewDocument.on('click', (event:any, data:any) => {
    // Get the clicked element in the view
    const clickedElement = data.domTarget;
    console.log("##### clickedElement #######", clickedElement)
    // Check if the clicked element is an image
    if (clickedElement.tagName == 'IMG') {
      // Get the image's source URL and other attributes
      const imageSrc = clickedElement.getAttribute('src');
      const width = clickedElement.getAttribute('width');
      const height = clickedElement.getAttribute('height');
      imageSrcc = imageSrc;
    }else{
      imageSrcc = '';
    }
  });
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return new MyCustomUploadAdapter(loader, maxFileSize, maxWidth, maxHeight,imageSrcc);
  };
}

export function MyCustomUploadAdapterPluginSignature(editor: any) {
  const maxFileSize = 1048576; // 1MB in bytes
  const maxWidth = 200; // Maximum width in pixels
  const maxHeight = 200; // Maximum height in pixels
  const viewDocument = editor.editing.view.document;
  let imageSrcc = ''; 
  // Listen for click events on the editor's view
  viewDocument.on('click', (event:any, data:any) => {
    // Get the clicked element in the view
    const clickedElement = data.domTarget;
    console.log("##### clickedElement #######", clickedElement)
    // Check if the clicked element is an image
    if (clickedElement.tagName == 'IMG') {
      // Get the image's source URL and other attributes
      const imageSrc = clickedElement.getAttribute('src');
      const width = clickedElement.getAttribute('width');
      const height = clickedElement.getAttribute('height');
      imageSrcc = imageSrc;
    }else{
      imageSrcc = '';
    }
  });
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return new MyCustomUploadAdapter(loader, maxFileSize, maxWidth, maxHeight, imageSrcc);
  };
}